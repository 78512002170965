import MobileDetect from 'mobile-detect';
import { proxied } from 'radash';
import request from '~libs/request';

enum GameRenderTypeEnum {
  Redirect = 'redirect',
  Html = 'html',
}

export const onOpenCasinoURL = async (
  id: string,
  currency: string | undefined,
  walletId: string | undefined,
): Promise<string> => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.mobile();
  const response = await request<{ desktop: string; mobile: string }>({
    method: 'POST',
    url: `/casino/${id}/open`,
    data: {
      currency,
      walletId,
    },
  });
  return (isMobile ? response.mobile : response.desktop) as string;
};

export const onOpenGamingURL = async (
  providerId: string,
  gameCode: string,
  currency: string | undefined,
  walletId: string | undefined,
): Promise<string> => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.mobile();
  const response = await request<{
    desktop: string;
    mobile: string;
    html?: string;
    renderType: GameRenderTypeEnum;
  }>({
    method: 'POST',
    url: `/gaming/${providerId}/open/${gameCode}`,
    data: {
      currency,
      walletId,
    },
  });

  const renderType = proxied((prop) => {
    switch (prop) {
      case GameRenderTypeEnum.Html:
        return URL.createObjectURL(
          new Blob([response.html as string], { type: 'text/html' }),
        );
      case GameRenderTypeEnum.Redirect:
        return isMobile ? response.mobile : response.desktop;
    }
  });
  return renderType[response.renderType] as string;
};

export const onOpenSportURL = async (
  id: string,
  currency: string | undefined,
  walletId: string | undefined,
): Promise<string> => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.mobile();
  const response = await request<{ desktop: string; mobile: string }>({
    method: 'POST',
    url: `/sportsbook/${id}/open`,
    data: {
      currency,
      walletId,
    },
  });
  return (isMobile ? response.mobile : response.desktop) as string;
};
