import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ProviderCasinoResApiInterface } from '../../types/casino';
import { useCasinos } from '~hooks/use-casinos';
import getImageLists from '~libs/get-image-lists';
import objectToQueryString from '~libs/object-to-query-string';
import { ProviderTypeEnum } from '~constants/promotion';
import PageUrls from '~constants/page-urls';
import { encrypt } from '~libs/encrypt';
import HttpErrorHandler from '~components/http-error-handler';
import ProviderLoadingTemplate from '~components/widget/provider-loading-template';
import ImageCustomWidget from '~components/widget/image-custom-widget';
import BentoMenuWidget from '~components/widget/bento-menu-widget';
import { ArrangementEnum } from '~constants/etc';

const CasinoProviderContainer: FC<{
  locale: string;
  currency: string;
  walletObjectId: string | undefined;
}> = ({ locale, currency, walletObjectId }) => {
  const { t } = useTranslation('errors');
  const isLightMode = document.documentElement.className.includes('light');
  const { data: providersCasino, isLoading: isLoadingProvidersCasino } =
    useCasinos(currency);

  const providersCasinoMapped = useMemo<
    (ProviderCasinoResApiInterface & {
      logo: string;
    })[]
  >(() => {
    if (!providersCasino) {
      return [];
    }
    return providersCasino.map((gaming) => {
      const images = getImageLists(gaming?.images);
      return {
        ...gaming,
        logo: isLightMode ? images.logoDark : images.logo,
      };
    });
  }, [providersCasino, isLightMode]);

  const handleOpenCasinoProvider = (
    provider: ProviderCasinoResApiInterface,
  ): void => {
    try {
      const currencyFiltered = provider.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.CASINO,
        providerId: provider.id,
        currency: currencyFiltered,
        walletId: walletObjectId,
      });
      window.open(
        `/${locale}${PageUrls.OPEN_GAME}?qs=${encrypt(queryString)}`,
        '_self',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  if (isLoadingProvidersCasino) {
    return <ProviderLoadingTemplate />;
  }

  return (
    <div className="flex flex-col gap-2">
      {providersCasinoMapped.map((provider, i) => (
        <BentoMenuWidget
          arrangement={ArrangementEnum.Horizontal}
          icon={
            <ImageCustomWidget
              alt={`game-${provider.id}`}
              className="sm:mt-0"
              image={provider.logo}
            />
          }
          key={i}
          onClick={() => {
            handleOpenCasinoProvider(provider);
          }}
          text={provider.title || t('not-found-provider')}
        />
      ))}
    </div>
  );
};

export default CasinoProviderContainer;
