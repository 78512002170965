import MobileDetect from 'mobile-detect';
import { proxied } from 'radash';
import request from '~libs/request';
import { LottoRedirectPathEnum } from '~constants/etc';

const lottoRedirectPath = proxied((prop: LottoRedirectPathEnum) => {
  switch (prop) {
    case LottoRedirectPathEnum.BetTransaction:
      return '/bet-transactions/lotto';
    case LottoRedirectPathEnum.LottoPrize:
      return '/lotto/prize';
    default:
      return '/lotto';
  }
});

const getPathLottoUrl = async (
  id: string | undefined,
  currency: string,
  walletId: string | undefined,
  redirectPath?: string | undefined,
): Promise<string> => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.mobile();
  const response = await request<{ desktop: string; mobile: string }>({
    method: 'POST',
    url: `/lotto/${id}/open`,
    data: {
      currency,
      walletId,
    },
  });
  const pathUrl = isMobile ? response.mobile : response.desktop;
  if (redirectPath) {
    return `${pathUrl}&redirect=${lottoRedirectPath[redirectPath]}`;
  }
  return pathUrl;
};

export default getPathLottoUrl;
