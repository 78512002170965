import useRequest from '~hooks/use-request';
import type { KeyedMutator } from 'swr';
import type { AxiosResponse } from 'axios';
import { ResLottoInterface } from '../types/lotto';

export const useLotto = (): {
  data: ResLottoInterface[] | undefined;
  isLoading: boolean;
  mutate: KeyedMutator<AxiosResponse<ResLottoInterface[]>>;
} => {
  return useRequest<ResLottoInterface[]>({
    method: 'GET',
    url: '/lotto',
    params: {
      includeExpire: 1,
    },
  });
};
