import type {
  ProviderSportResApiInterface,
  ResponseApiSportBetTransactionInterface,
} from 'types/sport';
import { useCallback } from 'react';
import { objectify } from 'radash';
import type { Config } from './use-request';
import useRequest from './use-request';

export const useSportsbook = (
  currency?: string,
  config?: Config<ProviderSportResApiInterface[]>,
) => {
  return useRequest<ProviderSportResApiInterface[]>(
    {
      url: '/sportsbook',
      params: {
        currency,
      },
    },
    {
      revalidateOnFocus: true,
      ...config,
    },
  );
};

export const useSportsbookInfoHelper = () => {
  const { data } = useSportsbook();
  const getSportProviderImage = useCallback(
    (provider: string) => {
      const sportProvider = data?.find((i) => i.id === provider);
      if (!sportProvider) {
        return;
      }
      return objectify(
        sportProvider?.images,
        (f) => f.name as string,
        (f) => f.value as string,
      );
    },
    [data],
  );
  return {
    getSportProviderImage,
  };
};

export const useSportBetTransactions = (
  query: any = {},
  config?: Config<ResponseApiSportBetTransactionInterface>,
) => {
  return useRequest<ResponseApiSportBetTransactionInterface>(
    {
      url: '/sportsbook/bet-transactions',
      params: {
        startAt: query.startAt,
        endAt: query.endAt,
        provider: query.provider,
      },
    },
    { ...config, revalidateOnFocus: true },
  );
};
