import type { FC } from 'react';
import React, { useState } from 'react';
import Image from 'next/image';
import { cn } from 'class-merge';
import images from '~build-config/images.json';

const ImageCustomWidget: FC<{
  alt: string;
  image: string;
  className?: string;
}> = (props) => {
  const { alt, image, className } = props;
  const [imageProps, setImageProps] = useState<{
    image: string;
    className: string;
  }>({
    image,
    className: '',
  });

  return (
    <Image
      alt={alt}
      className={cn(
        'rounded-t-lg object-cover sm:mt-1',
        imageProps.className,
        className,
      )}
      fill
      onError={() => {
        setImageProps({
          image: images.horizontalLogo,
          className: 'w-[48px] object-contain sm:mt-0',
        });
      }}
      src={imageProps.image}
      unoptimized
    />
  );
};

export default ImageCustomWidget;
