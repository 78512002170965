import { list } from 'radash';
import type { FC } from 'react';
import React from 'react';

const ProviderLoadingTemplate: FC<{
  count?: number;
}> = ({ count = 6 }) => {
  return (
    <div className="flex flex-col gap-2">
      {list(count).map((i) => (
        <div
          aria-hidden
          className="bg-color-evaluated min-h-[80px] w-full animate-pulse rounded-lg"
          key={i}
        />
      ))}
    </div>
  );
};

export default ProviderLoadingTemplate;
