export enum StatusVerifyBankAccount {
  INPROGRESS = 'in-progress',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum ProviderStatusEnum {
  ACTIVE = 'active',
  MAINTENANCE = 'maintenance',
  COMINGSOON = 'coming-soon',
}
