import type { FC } from 'react';
import React, { useMemo, useState, useEffect } from 'react';
import { CaretLeftOutlined, CaretRightOutlined } from '@packages/icons-react';
import { cn } from 'class-merge';
import Image from 'next/image';
import { useAnnouncement } from '~hooks/use-announcement';
import { AnnouncementShowOnTypeEnum } from '~constants/announcement';
import getImageLists from '~libs/get-image-lists';

const BannerContainer: FC<{
  locale: string;
}> = ({ locale }) => {
  const { data: announcementLists, isLoading: isLoadingAnnouncementLists } =
    useAnnouncement(locale);
  const [bannerPosition, setBannerPosition] = useState<number>(0);
  const [scrollPositionValue, setScrollPositionValue] = useState<number>(0);

  const handleChangeScrollPositionValue = (width: number): void => {
    if (width < 768) {
      setScrollPositionValue(width);
    } else if (width > 1024 && width < 1280) {
      setScrollPositionValue(750);
    } else if (width > 1280) {
      setScrollPositionValue(600);
    } else {
      setScrollPositionValue(768);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      handleChangeScrollPositionValue(window.innerWidth);
    });
    handleChangeScrollPositionValue(window.innerWidth);
    return () => {
      window.removeEventListener('resize', () => {
        handleChangeScrollPositionValue(window.innerWidth);
      });
    };
  }, []);

  const bannerLists = useMemo(() => {
    const announcementMapped =
      announcementLists?.map((announce, i) => {
        const images = getImageLists(announce?.images || []);
        return {
          showOn: announce?.showOn,
          objectId: announce?.objectId,
          banner: `${process.env.S3_BUCKET_ASSETS_URL}/${images.banner}`,
          isNotSupported: !images.banner,
        };
      }) || [];
    return announcementMapped?.filter(
      (res) =>
        res.showOn.includes(AnnouncementShowOnTypeEnum.Banner) &&
        !res.isNotSupported,
    );
  }, [announcementLists]);

  const disabledPrevAnnounceBth = bannerPosition === 0;
  const disabledNextAnnounceBth =
    bannerPosition === (bannerLists.length - 1) * scrollPositionValue;
  const showNavigationBtn = bannerLists.length > 1;

  if (isLoadingAnnouncementLists || !bannerLists.length) {
    return <div />;
  }

  return (
    <div className="relative -mt-5 mb-6 flex h-full min-h-[200px] w-full items-center overflow-hidden">
      {bannerLists.map((res, i) => (
        <div
          className="h-full w-full flex-none transition-all duration-700"
          key={i}
          style={{
            transform: `translateX(-${bannerPosition}px)`,
          }}
        >
          <Image
            alt="logo"
            className="h-full w-full"
            draggable={false}
            height={200}
            src={res.banner}
            unoptimized
            width={200}
          />
        </div>
      ))}
      {showNavigationBtn ? (
        <div className="absolute flex w-full items-center justify-between p-4 ">
          <CaretLeftOutlined
            className={cn(
              'text-xl',
              disabledPrevAnnounceBth
                ? 'cursor-not-allowed opacity-30'
                : 'cursor-pointer opacity-100',
            )}
            onClick={() => {
              if (disabledPrevAnnounceBth) {
                return;
              }
              setBannerPosition(bannerPosition - scrollPositionValue);
            }}
          />
          <CaretRightOutlined
            className={cn(
              'text-xl',
              disabledNextAnnounceBth
                ? 'cursor-not-allowed opacity-30'
                : 'cursor-pointer opacity-100',
            )}
            onClick={() => {
              if (disabledNextAnnounceBth) {
                return;
              }
              setBannerPosition(bannerPosition + scrollPositionValue);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default BannerContainer;
