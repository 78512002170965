import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosInstance } from './axios-instance';

export const parseJSON = (response): any => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.data;
};

export const checkStatus = (response): any => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  throw new Error(response);
};

async function request<T>(options: AxiosRequestConfig): Promise<T> {
  const fetchResponse: AxiosResponse<T> = await axiosInstance(options);
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}

export default request;
