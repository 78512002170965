import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { HomepageLayoutEnum } from '~constants/etc';
import appConfig from '~build-config/config.json';

const LayoutStore = persist<any>(
  (set, get) => ({
    layoutLists: {
      'homepage-layout':
        appConfig.homepageGridLayout || HomepageLayoutEnum.Grid,
    },
    setLayoutLists: (key) => {
      return (newValue) => {
        const layoutLists = get().layoutLists;
        set(() => ({
          layoutLists: {
            ...layoutLists,
            [key]: newValue,
          },
        }));
      };
    },
  }),
  {
    name: 'web-layout',
    partialize: (state) => ({
      layoutLists: state.layoutLists,
    }),
    version: 1,
  },
);

export const useLayoutStore = create(LayoutStore);
