import EncUTF8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';

const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function randomString(length: number): string {
  let rs = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    rs += characters.charAt(randomIndex);
  }
  return rs;
}

export const encrypt = (message: string, secretKey: string): string => {
  const key = EncUTF8.parse(secretKey.slice(0, 24));
  const iv = EncUTF8.parse(secretKey.slice(0, 16));
  const encrypted = AES.encrypt(
    randomString(1) + message + randomString(1),
    key,
    { iv },
  );
  return encrypted.toString();
};

export const decrypt = (message: string, secretKey: string): string => {
  const key = EncUTF8.parse(secretKey.slice(0, 24));
  const iv = EncUTF8.parse(secretKey.slice(0, 16));
  const decrypted = AES.decrypt(message, key, { iv });
  return decrypted.toString(EncUTF8).slice(1, -1);
};
