import { cn } from 'class-merge';
import type { CSSProperties, FC, ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import appConfig from '~build-config/config.json';
import { ArrangementEnum } from '~constants/etc';

interface BentoMenuWidgetProps {
  text: ReactNode;
  icon: ReactNode;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
  arrangement?: string;
  onClick: () => void;
  style?: CSSProperties | undefined;
}

const BentoMenuWidget: FC<BentoMenuWidgetProps> = (props) => {
  const {
    text,
    icon,
    disabled = false,
    arrangement = ArrangementEnum.Vertical,
    className = 'items-center justify-center',
    style,
    iconClassName,
    onClick,
  } = props;
  const { t } = useTranslation('etc');

  const classes = useMemo(() => {
    if (arrangement === ArrangementEnum.Vertical) {
      return {
        containerClassName: 'grid-rows-2 text-center pt-4 pb-2',
        iconWrapper: 'justify-center',
        textWrapper: '',
      };
    }
    return {
      containerClassName:
        'grid-cols-3 text-start items-center bg-color-evaluated pt-0 pb-0',
      iconWrapper: 'justify-end',
      textWrapper: 'col-span-2 text-sm sm:text-base',
    };
  }, [arrangement]);

  return (
    <div
      aria-hidden
      className={cn(
        'hover:bg-color-button-active text-color rounded-default grid h-[110px] w-full cursor-pointer gap-2 sm:h-[100px]',
        classes.containerClassName,
        disabled &&
          'hover:bg-color-evaluated cursor-not-allowed opacity-30 hover:text-white',
        className,
        appConfig.customClassName?.textHoverBentoMenu ||
          'hover:text-color-primary',
        appConfig.customClassName?.bgBentoMenu || 'bg-color-evaluated',
      )}
      onClick={() => {
        if (disabled) {
          return;
        }
        onClick();
      }}
      role="button"
      style={style}
    >
      <div className={cn('flex w-full items-center', classes.iconWrapper)}>
        <div
          className={cn(
            'relative flex h-[48px] w-[48px] items-center justify-center',
            iconClassName,
          )}
        >
          {icon}
        </div>
      </div>
      <div className={cn('truncate px-2 text-sm', classes.textWrapper)}>
        {text || t('not-found-data')}
      </div>
    </div>
  );
};

export default BentoMenuWidget;
